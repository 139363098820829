import React, { useState } from 'react';
import './FadeInComponent.scss';

const FadeInComponent = ({ children, time = 1000 }: { children: any, time?: number }) => {
  const [show, setShow] = useState(false);

  setTimeout(() => setShow(true), time);
  return (
    <div className={`fade-in-component ${show ? 'fade-in-component__show' : ''}`}>
      {children}
    </div>
  )
}

export default FadeInComponent;