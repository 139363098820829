import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import './App.scss';
import Routes from './../routes';
import HomePage from '../Pages/Home';

class App extends Component {
  render() {
    return (<Router>
      <div className='app'>
        <Switch>
          <Route path={Routes.home} exact component={HomePage} />
          <Route path='*' render={() => <Redirect to={Routes.home} />} />
        </Switch>
      </div>
    </Router>)
  }
}

export default App;
