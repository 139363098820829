import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import './Home.scss';
import FadeInComponent from './components/FadeInComponent';

class HomePage extends Component {
  TITLE_TIMEOUT = 3000;
  SUBTITLE_TIMEOUT = 5000;
  EMAIL_TIMEOUT = 7000;
  DESCRIPTION_TIMEOUT = 9000;

  state = {
    animation: {
      title: false,
      subtitle: false,
      email: false
    },
  }
  componentDidMount() {
    // Start the first animation
    setTimeout(() => this.startAnimation('title'), this.TITLE_TIMEOUT)
    setTimeout(() => this.startAnimation('subtitle'), this.SUBTITLE_TIMEOUT)
    setTimeout(() => this.startAnimation('email'), this.EMAIL_TIMEOUT)
  }

  startAnimation = (section: 'title' | 'subtitle' | 'email') => {
    let { animation } = this.state;
    switch (section) {
      case 'title':
        animation.title = true;
        break;
      case 'subtitle':
        animation.subtitle = true;
        break;
      case 'email':
        animation.email = true;
        break;
    }
    this.setState({ animation });
  }
  render() {
    const { animation } = this.state;
    return <div className='home'>
      <Row style={{ width: '100%' }}>
        <Col xs={12} md={12} lg={7}>
          <div className='home-container'>
            <div className='text-center'>
              <div style={{ display: 'inline-block' }}>
                <h1 className={`home-title ${animation.title ? !animation.subtitle ? 'home-animation' : 'home-show' : ''}`}>Hi, I'm Roberto</h1>
              </div>
              <br />
              <div style={{ display: 'inline-block' }}>
                <h2 className={`home-subtitle ${animation.subtitle ? !animation.email ? 'home-animation' : 'home-show' : ''}`}>Software Engineer</h2>
              </div>
              <br />
              <div style={{ display: 'inline-block' }}>
                <h3 className={`home-email ${animation.email ? 'home-animation' : ''}`}>roberto@franzet.com</h3>
              </div>
            </div>
            <br />
          </div>
        </Col>
        <Col xs={12} md={12} lg={5}>
          <FadeInComponent time={this.DESCRIPTION_TIMEOUT}>
            <div className='home-description'>
              <h2 className='text-center'>I'm a React developer</h2>
              <p className='text-center'>
                I love create components and all the magic that we can do with them.
          </p>
              <br />
              <h2 className='text-center'>
                Do you want to learn more about React?
              </h2>
              <br />
              <p className='text-center'>
                I'm working on a little guide about React. <br /> <span>I'll have it ready soon</span>
              </p>
            </div>
          </FadeInComponent>
        </Col>
      </Row>
    </div>
  }
}

export default HomePage;
